<div class="row">
    <div class="calendar">
        <ngb-datepicker #dp name="datum" (dateSelect)="onDateChange($event)" required></ngb-datepicker>
    </div>

    <div class="appointemnts">
        <div class="table">
            <table>
                <tr *ngFor="let item of data">
                    <td [ngClass]="{
                        'freeapps': item?.id == 0,
                        'zauzetapps': item?.id != 0
                    }">
                    
                        @if(item?.id ==0)
                        {
                             
                        }
                        @else {
                             
                        }
                    </td>
                    <td>
                        Datum: <b>{{ item?.time | date:'dd.MM.yyyy' }}</b><br>
                        Vreme: <b>{{ item?.time | date:'HH:mm' }}</b>
                    </td>
                    <td>
                        <ng-container *ngIf="item?.tipVozila">
                            <ng-container *ngIf="item.tipVozila === VehicleType.Putnicko_vozilo">
                                <img class="icon" src="../../../../assets/icons/putnickoptype.svg" />
                            </ng-container>
                            <ng-container *ngIf="item.tipVozila === VehicleType.Motor">
                                <img class="icon" src="../../../../assets/icons/motorbiketype.svg" />
                            </ng-container>
                            <ng-container
                                *ngIf="item.tipVozila !== VehicleType.Putnicko_vozilo && item.tipVozila !== VehicleType.Motor">
                                <img class="icon" src="../../../../assets/icons/truck.svg" />
                            </ng-container>
                        </ng-container>
                    </td>
                    <td *ngIf="item?.ime && item?.prezime">
                        @if(item.id != 0)
                        {
                        Ime: <b>{{ item.ime }}</b><br>
                        Prezime: <b>{{ item.prezime }}</b>
                        }
                    </td>
                    <td *ngIf="!item?.ime && !item?.prezime">
                        @if(item.id != 0)
                        {
                        Firma: <b>{{ item?.companyName }}</b><br>
                        PIB: <b>{{ item?.pib }}</b>

                        }
                    </td>
                    <td>{{ item?.vozilo }}</td>
                    <td>
                        {{ item?.telefon }}<br>
                        {{ item?.email }}
                    </td>
                    <td>
                        <span [ngClass]="{
                    cekanje: item?.status === 1,
                    odbijen: item?.status === 2,
                    odobren: item?.status === 3,
                    zavrsen: item?.status === 4,
                    nijesepojavio: item?.status === 5,
                    otkazao: item?.status === 6
                  }">
                            {{ TerminStatus[item.status]?.toString()?.replaceAll('_', ' ') }}
                        </span>
                    </td>
                    <td class="actions"
                        *ngIf="isAdmin() && (item?.status === TerminStatus.Nije_se_pojavio || item?.status === TerminStatus.Zavrsen)">
                    </td>

                    <td class="actions" *ngIf="item?.id == 0 ">
                        <img class="icons" title="Rezervisi termin"
                        (click)="$event.stopPropagation(); navigate(item.time)"
                        src="../../../../assets/icons/rezervisitermin.svg" />REZERVISI TERMIN
                    </td>
                    <td class="actions" *ngIf="item?.id !== 0 && isAdmin() && item?.status === TerminStatus.Na_cekanju">
                        <img class="icons" title="Potvrdi vozilo"
                            (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odobren, item.id)"
                            src="../../../../assets/icons/approve.svg" />Odobri
                        <img class="icons" title="Odbij vozilo"
                            (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Odbijen, item.id)"
                            src="../../../../assets/icons/reject.svg" />Odbij
                    </td>
                    <td class="actions" *ngIf="item?.id !== 0 && isAdmin() && item?.status === TerminStatus.Odobren">
                        <img class="icons" title="Pojavio se"
                            (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Zavrsen, item.id)"
                            src="../../../../assets/icons/approve.svg" />Pojavio se
                        <img class="icons" title="Nije se pojavio"
                            (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Nije_se_pojavio, item.id)"
                            src="../../../../assets/icons/reject.svg" />Nije se pojavio
                        <img class="icons" title="Otkazao"
                            (click)="$event.stopPropagation(); approveAppointment(TerminStatus.Otkazao, item.id)"
                            src="../../../../assets/icons/reject.svg" />Otkazao
                    </td>
                    <td class="actions"
                        *ngIf="isAdmin() && (item?.status === TerminStatus.Nije_se_pojavio || item?.status === TerminStatus.Zavrsen || item?.status === TerminStatus.Odbijen|| item?.status === TerminStatus.Otkazao)">
                    </td>
                </tr>
            </table>

        </div>
    </div>
</div>